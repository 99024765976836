// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';
import { Outlet, useLocation } from 'react-router-dom';
import { WebRoutesGenerator } from '../../components/src/NavigationRouteWrapper/Web';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Avatar from "../../blocks/avatar/src/Avatar";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Analytics from "../../blocks/analytics/src/Analytics";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Videos from "../../blocks/videos/src/Videos";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import DragDropInterface from "../../blocks/dragdropinterface/src/DragDropInterface";
import Maps from "../../blocks/maps/src/Maps";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import Cftimelines from "../../blocks/cftimelines/src/Cftimelines";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Sorting from "../../blocks/sorting/src/Sorting";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Customisableusersubscriptions from "../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions";
import SubscriptionDetails from "../../blocks/customisableusersubscriptions/src/SubscriptionDetails";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Onboardingguide from "../../blocks/onboardingguide/src/Onboardingguide";
import Onlinebackup from "../../blocks/onlinebackup/src/Onlinebackup";


const routeMap = {
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
Avatar:{
 component:Avatar,
path:"/Avatar"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
Videos:{
 component:Videos,
path:"/Videos"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
DragDropInterface:{
 component:DragDropInterface,
path:"/DragDropInterface"},
Maps:{
 component:Maps,
path:"/Maps"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
Cftimelines:{
 component:Cftimelines,
path:"/Cftimelines"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Sorting:{
 component:Sorting,
path:"/Sorting"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Customisableusersubscriptions:{
 component:Customisableusersubscriptions,
path:"/Customisableusersubscriptions"},
SubscriptionDetails:{
 component:SubscriptionDetails,
path:"/SubscriptionDetails"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
Onboardingguide:{
 component:Onboardingguide,
path:"/Onboardingguide"},
Onlinebackup:{
 component:Onlinebackup,
path:"/Onlinebackup"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true,
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage',
  },
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

const defaultAnalytics = firebaseAPI.analytics();
defaultAnalytics.logEvent('APP_Loaded');

function App() {
  return (
    <BuilderProvider>
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        <WebRoutesGenerator routeMap={routeMap} />
        <Outlet />
        <AlertBlock />
      </View>
    </BuilderProvider>
  );
}

export default App;